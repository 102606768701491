<p-fileUpload #uploader name="myfile[]" [files]="fileList" [multiple]="true" [chooseLabel]="buttonLabel | translate"
    [showUploadButton]="false" [showCancelButton]="false" [customUpload]="customUpload" [maxFileSize]="maxFileSize"
    [fileLimit]="limit" (onSelect)="select($event)" invalidFileSizeMessageDetail="" invalidFileTypeMessageDetail=""
    [invalidFileSizeMessageSummary]="invalidFileSizeMessage()"
    invalidFileTypeMessageSummary="{{'UPLOAD.ERRORS.INVALID_FILE_TYPE_DETAILS'| translate}}" [accept]="accept"
    [disabled]="control.disabled">
    <ng-template let-file pTemplate="file" class="selectedFiles">
        <div class="bg-gray-100 my-4 flex ng-star-inserted px-6 py-4 rounded-large selected-file shadow-3">
            <div class="file-details my-auto grid gap-2 w-1/2">
                <div class="col-3">
                    <div class="text-md"> {{ formatFileName(file) }} </div>
                    <div class="text-xs"> {{ file.size / 100 }} KB </div>
                </div>
                <p-image *ngIf="isImage(file)" [src]="file.safeValue" alt="Image" width="50"></p-image>
                <div *ngIf="!isImage(file)" [class]="'pi pi-' + getFileIcon(file)"
                    class="text-6xl text-bluegray-300 my-auto"></div>
            </div>
            <div class="mr-auto my-auto text-lg close pi pi-trash cursor-pointer fade" (click)="removeFile(file)"></div>
        </div>
    </ng-template>
</p-fileUpload>