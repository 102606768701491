<form [formGroup]="UserDetailsForm" name="userDetails" autocomplete="off" class=" grid mt-3">
    <div class="grid p-fluid">

        <!-- FirstName -->
        <div class="form-group col-6 p-field p-float-label">
            <span class="p-float-label">
                <input pInputText formControlName="firstName" name="firstName" id="firstName" />
                <label for="firstName">{{'USER.FIRST_NAME' | translate}}</label>
            </span>
        </div>

        <!-- LastName -->
        <div class="form-group col-6 p-field p-float-label">
            <span class="p-float-label">
                <input pInputText formControlName="lastName" name="lastName" id="lastName" />
                <label for="lastName">{{'USER.LAST_NAME' | translate}}</label>
            </span>
        </div>

        <!-- Phone -->
        <div class="form-group col-8 ">
            <span class="p-float-label">
                <input pInputText formControlName="phone" name="phone" id="phone" (onChange)="onChangePhone()" />
                <label for="phone">{{'USER.PHONE' | translate}}</label>
                <div class="flex fs-small text-gray-400">{{'TOP_MENU.USER_DETAILS.FIELD_MUST_VERIFY' | translate}}</div>
            </span>
        </div>
        <p-button *ngIf="!phoneVerificationSuccess" label="{{'TOP_MENU.USER_DETAILS.CONFIRM' | translate}}"
            class="col-4" (click)="openVerifyPopup(2)"></p-button>
        <div class="col-4" *ngIf="phoneVerificationSuccess">
            <button class="success gap-1" pButton type="button">
                <span style="width: 100%;"> <i class="pi pi-check"></i> {{'TOP_MENU.USER_DETAILS.SUCCESS_CONFIRM'
                    |translate}} </span>
            </button>
        </div>

        <!-- Mail -->
        <div class="form-group col-8 p-field p-float-label">
            <span class="p-float-label">
                <input pInputText formControlName="mail" name="mail" id="mail" (change)="onChangeMail()" />
                <label for="mail">{{'USER.MAIL' | translate}}</label>
                <div class="flex fs-small text-gray-400">{{'TOP_MENU.USER_DETAILS.FIELD_MUST_VERIFY' | translate}}</div>
            </span>
        </div>
        <p-button *ngIf="!mailVerificationSuccess" label="{{'TOP_MENU.USER_DETAILS.CONFIRM' | translate}}" class="col-4"
            (click)="openVerifyPopup(1)"></p-button>
        <div class="col-4" *ngIf="mailVerificationSuccess">
            <button class="success gap-1" pButton type="button">
                <span style="width: 100%;"> <i class="pi pi-check"></i> {{'TOP_MENU.USER_DETAILS.SUCCESS_CONFIRM'
                    |translate}} </span>
            </button>
        </div>

    </div>
</form>

<dynamic-popup *ngIf="showVerifyPopup" [header]="header" [mode]="popupModes.Success" [modal]="true"
    [visible]="showVerifyPopup" [showCancelButton]="false" [showAcceptButton]="false" [style]="{width: '400px'}"
    [closeOnSave]="false" (cancel)="closeModal()">
    <phone-or-mail-verification [phoneNumber]="phoneNumber" [phoneOrMail]="type"
        (afterVerification)="afterVerification($event)"></phone-or-mail-verification>
</dynamic-popup>