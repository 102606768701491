import { ElementRef, TemplateRef, Type } from "@angular/core";
import { Validators } from "@angular/forms"
import { ExportType } from "../enum";


//#region Export classes
export enum ButtonEnableOption {
    always,
    inSingleSelect,
    inMultySelect
}
export enum ButtonDisplayOption {
    always,
    inSingleSelect,
    inMultiSelect,
    inSingleOrMultiSelect
}
//#region buttons
export interface CustomButton {
    buttonType: ButtonType;
    label: string,
    displayOptions: ButtonDisplayOption,
    enableOptions: ButtonEnableOption,
    icon: string,
    onClick: any,
    tooltip: string,
    menuItems: MenuItemOptions[],
    data: any,
    displayCond: CallableFunction, // = null
    enableCond: CallableFunction,//null
    disableTooltip: string,
    iconUrl: string//the url of the img that we want do dispaly as icon

    //apiAddress:string 
}
//#endregion

//#region columns

export interface CustomColumnParams {
}

export class EnumParams implements CustomColumnParams {
    enumType: any;
    enumName:string;
    constructor(enumType: any,enumName:string) {
        this.enumType = enumType;
        this.enumName = enumName;
    }
}

export class ButtonParams implements CustomColumnParams {
    text: string;
    icon: string;
    tooltip: string;
    //class:any;
    clickCallback: (any) => any;
    //classCondition:any;
    constructor(text: string, cClass: any, classCondition: any, clickCallback: (any) => any, icon: string = "", tooltip: string = "") {
        this.text = text;
        //this.class = cClass;
        this.clickCallback = clickCallback;
        this.icon = icon;
        this.tooltip = tooltip;
        //this.classCondition=classCondition;
    }
}
export class Checkbox implements CustomColumnParams {
    checked: any;
    onCheck: (any) => any;
    onUnCheck: (any) => any;
    disable: (any) => any;

    constructor(checked: any, onCheck: () => any, disable: (any) => any = () => false) {
        this.checked = checked;
        this.onCheck = onCheck;
        this.onUnCheck = onCheck;
        this.disable = disable;
    }
}
export class CheckboxIcon implements CustomColumnParams {
    trueIcon: string;
    falseIcon: any;
    clickCallback: (any) => any;
    constructor(trueIcon: string, falseIcon: string, clickCallback: (any) => any) {
        this.trueIcon = trueIcon;
        this.falseIcon = falseIcon;
        this.clickCallback = clickCallback;
    }
}

export class TemplateParams implements CustomColumnParams {
    constructor(private view: ElementRef<any>, private colName: string = null) { }
}

//#endregion

//#endregion

//#region Export interfaces
export interface DropdownFilter {
    options: any[];
    //optionsResponse$:Observable<any>;
    selectedData: any;
    optionLabel: string;
    optionValue: string;
    filterName: string;
    singleClickEdit: true;
}
export interface ActionsEvents {
    edit: (any) => any
    add: (any) => any
    delete: (any) => any
}
export interface ColumnOptions {
    field: string;
    headerName: string;
    filter: FilterType;
    type: ColumnType;
    customColumnParams: CustomColumnParams;
    cellRendererParams: object,
    sortable: boolean; // = true,
    editable: any; // = false
    hideColumn: CallableFunction; // = () => false
    style: any[]; // = []
    customFilterParams: any; // = {},
    headerComponentParams: any,
    onClick: any,
    cellClassRules: any;
    width: number;
    valueFormatter: any,
    lock: boolean;
    compareType: CompareType,
    disableEditing: boolean,
    validators: Validators[],
    wrapText: boolean; // = false
    valueCondition: (any) => any,
    value: any,
    customValueParams: any,
    valueParser: any,
    cellEditorSelector: any,
    cellEditorParams: any;
}
export interface StatusOptions {
    statusEnumType: any;
    statusEnumName:string;
    statusList: any[];
    selectedStatus: any;
    onChange: CallableFunction;
    statusIconEnumType: any;
}
export interface GridOptions {
    gridType: DataLoadType //server
    rowSelectionType: RowSelectionType //= RowSelectionType.multiple;
    rowSelectionCondition: (any) => boolean; // () => true
    multiSort: boolean //= true;
    buttons: CustomButton[];
    status: Partial<StatusOptions>
    dropdownfilter: DropdownFilter,
    fitColumns: boolean // = false,
    sortable: boolean, // = trueת
    showSearch: boolean // = false,
    showCreditNumberSearch: boolean // = false,
    saveTableState: boolean // = true,
    onCellValueChanged: (event: any) => void,
    contextMenu: CallableFunction,
    getRowStyle: any,
    rowStyle: any,
    usingTopCalendar: boolean // = false,
    showSelectionColumn: boolean // = true,
    customToolPanels: any[] // []
    displayTitle: boolean;
    masterDetail: boolean;
    frameworkComponents;
    masterDetailsComponent;
    masterDetailsData?: () => {};
    detailRowHeight?: number;//=800
    cacheBlockSize: number;
    selectFirstRow: boolean; // = false
    onNavigateToNextCell: (event: any) => void;
    displaySidebarColumns: boolean; // = true 
    displaySidebarFilter: boolean; // = true
    selectAllRows: boolean; // = false ,
    showSelectAll: boolean;
    // suppressRowClickSelection: boolean,
    isRowMaster: (params: any) => boolean;
    rowDetailsTitle: string;
    displayRowDetailsMenu: boolean; // = true
    // editSettings:
    // {allowAdd:boolean, 
    //  allowDeleted:boolean,
    //  allowEdit:boolean,
    //  addSettings?:{
    //      row:any,
    //      buttonLabel:string
    //  }
    //  onAddRow?:() =>any,
    //  onDeleteRow?:(any) => any,
    //  onSaveChanges?:(row:any,updatedField:string) => any}
    editSettings: {
        allowDeleted: boolean,
        allowEdit: boolean,
        allowAdd?: boolean,
        onAddRow?: () => any,
        addSettings?: {
            row: any,
            buttonLabel: string
        }
        addRowSettings?: {
            allowAdd: boolean,
            allowAddCondition?: (any) => boolean,
            onAddRow?: (any) => any,
            newRowForm?: TemplateRef<any>,
            addRowLabel?: string
        },
        deleteRowSettings?: {
            allowDelete: boolean,
            onDeleteRow?: (any) => any,
            deleteRowLabel?: string
        }
        onSaveChanges?: (row: any, updatedField: string) => any
    }
    minimumVisibleColumns: number;// = 0 ,
    VisibleColumnsNames: string[];// = [] ,
    serverSideStoreType: string;
    rowDetailsHiddenFields: string[];
    noRowsOverlayComponentMessage: string
}
export interface loadDataParameter {
    filter: filter[],
    sort: sort[],
    Pagination: Pagination,
    freeSearchText: string,
    searchCreditNumberText: string,
    status: any
}
export interface filter {
    Operator: CompareType,
    Field: string,
    FieldType: FieldType,
    Value: any,
}
export interface sort {
    Field: string,
    SortType: SortType
}
export interface Pagination {
    Limit: number,
    PageIndex: number
}
export interface PipeRendererParams {
    prefix: { field: string, sep: string, value: string },
    suffix: { field: string, sep: string, value: string },
    pipe: { type: Type<any>, params: any[] },
    class: string[],
    ignoreValue?: string
}
export interface HeaderMoreInformation {
    title: string,
    number: any,
    details: string
}
//#endregion

//#region Export enums

export enum ButtonType {
    Delete,
    New,
    Export,
    View,
    FilterDisplay,
    Custom,
    Screenshot,
    ViewImages,
    Upload
}
export enum SortType {
    asc = 1,
    desc = 2,
}
// export enum ColumnType {
//     Defualt,
//     IconBool,
//     Bool,
//     Enum
// }
export enum ColumnType {
    Defualt,
    Enum,
    Pipe,
    Template,
    Button,
    Checkbox,
    CheckboxIcon,
    Boolean,
    Link,
    Model,
    Distance,
    Combined,
    NoLimit,
    TemplateParams
}
export enum CompareType {
    contains = 0,
    equals = 1,
    between = 2,
}
export enum DataLoadType {
    local = 1,
    server
}
export enum RowSelectionType {
    multiple = 1,
    single
}
export enum FilterType {
    date = "agDateColumnFilter",
    text = "agTextColumnFilter",
    number = "agNumberColumnFilter",
    set = "agSetColumnFilter",
    customDate = "agDateColumnFilter",
    list = "listColumnFilter",
}
export enum ValueType {
    NoLimit
}
export enum CellStyle {
    date = 1,
    credit = 2,
    notValid = 3,
    button = 4
}
export enum FilterMode {
    show,
    hidden
}
export enum FieldType {
    date,
    string,
    enum,
    dateRange
}
//#endregion

//#region Export properties

export let defualtGridOptions: GridOptions = {
    gridType: DataLoadType.server,
    rowSelectionType: RowSelectionType.multiple,
    rowSelectionCondition: () => true,
    multiSort: true,
    buttons: [],
    status: null,
    dropdownfilter: null,
    fitColumns: false,
    sortable: true,
    showSearch: true,
    showCreditNumberSearch: false,
    saveTableState: true,
    customToolPanels: [],
    onCellValueChanged: (event: any) => { },
    contextMenu: (params: any) => { },
    getRowStyle: (params: any) => { },
    rowStyle: {},
    masterDetail: false,
    usingTopCalendar: false,
    showSelectionColumn: true,
    displayTitle: true,
    frameworkComponents: null,
    masterDetailsComponent: null,
    selectFirstRow: false,
    onNavigateToNextCell: null,
    displaySidebarColumns: true,
    cacheBlockSize: 100,
    isRowMaster: () => true,
    rowDetailsTitle: null,
    displaySidebarFilter: true,
    selectAllRows: false,
    showSelectAll: false,
    // suppressRowClickSelection: false,
    editSettings: { allowAdd: false, allowDeleted: false, allowEdit: false, addSettings: null },
    minimumVisibleColumns: 0,
    VisibleColumnsNames: [],
    serverSideStoreType: 'partial',
    displayRowDetailsMenu: true,
    rowDetailsHiddenFields: [],
    noRowsOverlayComponentMessage: null
};
export let defualtColumnOptions: ColumnOptions = {
    field: "",
    headerName: "",
    filter: null,
    type: ColumnType.Defualt,
    customColumnParams: null,
    cellRendererParams: null,
    sortable: true,
    editable: false,
    hideColumn: () => false,
    style: [],
    customFilterParams: {},
    headerComponentParams: {},
    onClick: null,
    cellClassRules: null,
    width: null,
    valueFormatter: null,
    // iconBoolType: null,
    lock: false,
    compareType: null,
    disableEditing: false,
    validators: null,
    wrapText: false,
    valueCondition: null,
    value: null,
    customValueParams: null,
    valueParser: null,
    cellEditorSelector: null,
    cellEditorParams: {
        cellHeight: 30
    }
};
export let deleteButton: CustomButton = {
    buttonType: ButtonType.Delete,
    label: null,
    displayOptions: ButtonDisplayOption.always,
    enableOptions: ButtonEnableOption.inMultySelect,
    icon: "pi pi-trash",
    onClick: null,
    tooltip: "GRID.HEADER.TOOLTIP.DELETE",
    menuItems: null,
    data: null,
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    iconUrl: null


};
export let newButton: CustomButton = {
    buttonType: ButtonType.New,
    label: null,
    displayOptions: ButtonDisplayOption.always,
    enableOptions: ButtonEnableOption.always,
    icon: "pi pi-plus",
    onClick: null,
    tooltip: "GRID.HEADER.TOOLTIP.ADD",
    menuItems: null,
    data: null,
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    iconUrl: null

};

export let screenshotButton: CustomButton = {
    buttonType: ButtonType.Screenshot,
    label: null,
    displayOptions: ButtonDisplayOption.always,
    enableOptions: ButtonEnableOption.always,
    icon: "pi pi-camera",
    onClick: null,
    tooltip: null,
    menuItems: null,
    data: null,
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    iconUrl: null

};

export let viewImagesButton: CustomButton = {
    buttonType: ButtonType.ViewImages,
    label: null,
    displayOptions: ButtonDisplayOption.always,
    enableOptions: ButtonEnableOption.always,
    icon: "pi pi-images",
    onClick: null,
    tooltip: null,
    menuItems: null,
    data: null,
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    iconUrl: null

};

export let exportButton: CustomButton = {
    //apiAddress: string ,
    buttonType: ButtonType.Export,
    // label: "GRID.HEADER.TOOLTIP.EXPORT",
    label: null,
    enableOptions: ButtonEnableOption.always,
    displayOptions: ButtonDisplayOption.always,
    onClick: null,
    tooltip: "GRID.HEADER.TOOLTIP.EXPORT",
    menuItems: null,
    data: null,
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    icon: "pi pi-file-excel"
    // icon: "iconUrl",
    // iconUrl: "assets/images/icons/excel.png"
    ,
    iconUrl: null
};
export let uploadButton: CustomButton = {
    //apiAddress: string ,

    buttonType: ButtonType.Upload,
    // label: "GRID.HEADER.TOOLTIP.EXPORT",
    label: null,
    enableOptions: ButtonEnableOption.always,
    displayOptions: ButtonDisplayOption.always,
    onClick: null,
    tooltip: "GRID.HEADER.TOOLTIP.UPLOAD",
    menuItems: null,
    data: null,
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    icon: "pi pi-upload",
    iconUrl: null

    // icon: "iconUrl",
    // iconUrl: "assets/images/icons/uploadExcel.png"

};
export let ChangeModeButton: CustomButton = {
    buttonType: ButtonType.Export,
    enableOptions: ButtonEnableOption.always,
    displayOptions: ButtonDisplayOption.always,
    icon: "",
    onClick: null,
    tooltip: "",
    menuItems: null,
    data: null,
    label: "",
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    iconUrl: null

};
export let viewButton: CustomButton = {
    buttonType: ButtonType.View,
    label: null,
    enableOptions: ButtonEnableOption.inSingleSelect,
    displayOptions: ButtonDisplayOption.always,
    icon: "file-excel",
    onClick: null,
    tooltip: "",
    menuItems: null,
    data: null,
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    iconUrl: null

}
export let emptyButton: CustomButton = {
    //apiAddress: string ,

    buttonType: ButtonType.Custom,
    label: null,
    enableOptions: ButtonEnableOption.always,
    displayOptions: ButtonDisplayOption.always,
    icon: "",
    onClick: null,
    tooltip: "",
    menuItems: null,
    data: null,
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    iconUrl: null

}
export let exportPdfButton: CustomButton = {
    //apiAddress: string ,
    buttonType: ButtonType.Export,
    enableOptions: ButtonEnableOption.always,
    displayOptions: ButtonDisplayOption.always,
    icon: "pi pi-print",
    label: "GENERAL.PRINT",
    onClick: null,
    tooltip: "GRID.HEADER.TOOLTIP.EXPORT_PDF",
    menuItems: null,
    data: null,
    displayCond: null,
    enableCond: null,
    disableTooltip: null,
    iconUrl: null
}

export let pipeRendererParams: PipeRendererParams = {
    prefix: {
        field: "",
        sep: "",
        value: ""
    },
    suffix: {
        field: "",
        sep: "",
        value: ""
    },
    pipe: {
        type: null,
        params: []
    },
    class: [],
    ignoreValue: null
}

export let globalSideBar: any = {
    toolPanels: [
        {
            id: 'quickScan',
            labelDefault: 'סקירה מהירה --',
            labelKey: 'quickScan',
            // iconKey: 'menu',
            toolPanel: 'customQuickScanToolPanel',
            width: 450
        },
        {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
                suppressPivots: true,
                suppressPivotMode: true,
                suppressValues: true,
                suppressRowGroups: true
            }
        },
        {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
        }
    ],
    closedByDefault: true,
};

export class MenuItemOptions {
    label: string;
    onClick: () => any;
    fileName?: string;
    //for dynamic name 
    getFileName?:()=>string;
    exportType?: ExportType;
}

// export let headerMoreInformation:HeaderMoreInformation={
//     title: "",
//     number: 0,
//     details: ""
// }
//#endregion

