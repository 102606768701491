<div style="height: inherit; display: flex; flex-flow: column; padding-left: 8px;">
  
  <grid-header style=" flex: 0 1 auto;" [(selectedStatus)]="selectedStatus" [statusList]="options?.status?.statusList"
    [enumType]="options?.status?.statusEnumType"  [enumName]="options?.status?.statusEnumName" [status]='options?.status'
    [statusIconEnumType]="options?.status?.statusIconEnumType" [buttons]="options?.buttons"
    (customButtonClick)="customButtonClick($event)" [dropdownfilter]="options?.dropdownfilter"
    [displayTitle]="options?.displayTitle" (dropdownfilterChange)="dropdownfilterChange($event)"
    [selectedItems]="selectedData" [sumHeaderCount]="totalCount" [sumHeaderText]="sumHeaderText"
    [(freeSearchText)]="freeSearchText" [showSearch]="options?.showSearch"
    [(searchCreditNumberText)]="searchCreditNumberText" [showCreditNumberSearch]="options?.showCreditNumberSearch"
    [subTitle]="subTitle" [project]="project" [subStatusMenu]="subStatusMenu"[leftSubStatusMenu]="leftSubStatusMenu" [searchText]="searchText"
    [onLoading]="onLoading" [addRowSettings]="options.editSettings?.addRowSettings" (rowAdded)="newRowAdded()"
    [headerMoreInformation]="headerMoreInformation" [calander]="calander" [isSummeryTable]="isSummeryTable" [note]="note" [anotherSearch]="anotherSearch"></grid-header>
  <!-- <div class="grid-container w-full" > -->
  <!-- class="flex gap-2" -->
  <div class="flex" style="  flex: 1 1 auto;">
  
    <!-- Grid header -->

    <ng-container [ngTemplateOutlet]="summeryTable"></ng-container>

    <!-- Grid content -->
    <div style="height: 100%;flex: 1 1 auto;" [class]="onLoading ? 'k-loading' : ''">

      <!-- Grid -->
      <ag-grid-angular   
        #agGrid [style]="{width: '100%', height: '100%'}" class="ag-theme-alpine"
        [columnDefs]="columns" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"
        (cellValueChanged)="options.onCellValueChanged($event)" [getContextMenuItems]="getContextMenuItems" [rowStyle]="options?.rowStyle"
        (columnVisible)="saveState($event)" (columnMoved)="saveState($event)" [getRowStyle]="options?.getRowStyle"
        [navigateToNextCell]="navigateToNextCell.bind(this)" [sideBar]="sideBar" [enableCellTextSelection]="true"
        [allowDragFromColumnsToolPanel]=true [isRowMaster]="options.isRowMaster" [getRowId]="getRowId"
        [rowData]="rowData" [editType]="editType" [modules]="modules">
      </ag-grid-angular>

      <!-- Grid loader template -->
      <!-- <grid-loader *ngIf="onLoading" [columns]="getTableColumnsCount()"></grid-loader> -->

      <!-- Grid no data found template -->
      <!-- <div
        *ngIf="!onLoading && totalCount == 0 && (!loadDataParameters || !loadDataParameters.filter || loadDataParameters.filter.length == 0)"
        class="noData">
        <p>{{'GENERAL.GRID_THERE_IS_NO_DATA'|translate}}</p>
      </div> -->
    </div>

  </div>
</div>
<!-- </div> -->
<ng-template #saveChangesButton let-data>
  <div *ngIf="data.id==0">
    <button pButton class="p-button-outlined bg"
    label="'שמור'"
    (click)="saveChanges()" icon="pi pi-save" iconPos="left">
</button>
    <!-- <button pButton (click)="saveChanges()" icon="pi pi-save"
      class="p-button p-button-icon-only p-button-outlined p-button-raised p-button-rounded" [pTooltip]="'שמור'"
      tooltipPosition="bottom"></button> -->
  </div>
</ng-template>
<ng-template #cancelChangesButton let-data>
  <div *ngIf="data.id==0">
    <button pButton class="p-button-outlined bg"
    label="'בטל'"
    (click)="cancelChanges()" icon="pi pi-undo" iconPos="left">
</button>
    <!-- <button pButton (click)="cancelChanges()" icon="pi pi-undo"
      class="p-button p-button-icon-only p-button-outlined p-button-raised p-button-rounded" [pTooltip]="'בטל'"
      tooltipPosition="bottom"></button> -->
  </div>
</ng-template>


<p-dialog  [(visible)]="displayRowDetails" [dismissableMask]="true"   [style]="onMobile() ? {width: '100%'} : {width: '25%'}" >
  <h5 class="text-center gap-1 header mx-2">
    <i class="font-bold ml-2 my-auto pi text-2xl mr-auto modeIcon"
        class="pi pi-file"></i>
    <div class="my-auto ml-auto"> {{ 'GRID.COLUMNS.ROW_DETAILS' | translate }} </div>
</h5>
  <view-object-details
  *ngIf="displayRowDetails"
  [object]="selectedData"
  [displayKeys]="getDisplayKeysFromGridColumnsDefinition()"
  [contextMenu]="options?.contextMenu">
</view-object-details>
  </p-dialog>