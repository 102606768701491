import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvMessageService, defaultPopupData, DynamicPopupService, LocalizationService, MessageTypes, TableType } from 'projects/shared/src/public-api';
import { ChangesComponent } from '../components/changes/changes.component';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private httpClient: HttpClient,
    private dynamicPopupService: DynamicPopupService,
    private translateService: LocalizationService,
    private _advMessageService: AdvMessageService,
  ) { }
  changesHistory(id: number, table: TableType = 1, titleWhenThereAreNoChanges: string) {
    return this.httpClient.get('/History/getChangesHistory/' + table + '/' + id).subscribe((res: any) => {
      if (!res.entities.length) {
        {
          let title=this.getTranslation('GENERAL.NO_CHANGES_TO')+this.getTranslation(titleWhenThereAreNoChanges)
          this._advMessageService.showMessage(MessageTypes.info,title);
        }
      }
      else {
        this.open(res);
      }
    });
  }
  private open(res) {
    let data = {
      component: ChangesComponent,
      popupData: {
        ...defaultPopupData,
        modal: true,
        header: this.getTranslation('CUSTOMERS.CARD.CHANGES_HISTORY'),
        style: { width: '80rem' },
        showAcceptButton:false,
        showCancelButton: false,
        showIcon: false
      },
      componentData: {
        changes: res.entities,

      }
    };
    this.dynamicPopupService.openDynamicPopupClicked.emit(data);
  }
  private getTranslation(translationWord: string, parameter?: undefined) {
    return this.translateService.Translate(translationWord, parameter)
  }
}
