<div [formGroup]="formGroup">
    <div class="form-group" [updateValidations]="isUpdateValidations">
        <span  [ngClass]="flexStyle==true? 'flex flex-row-reverse font-bold':'p-float-label'">
            <input *ngIf="field.fieldType==fieldType.Text" class="input" id="field.fieldName" pInputText
                name="field.fieldName" autocomplete="new-sett" value="{{field.value}}" (change)="onChangeField($event)"
                [formControlName]="field.fieldId" />

            <p-inputNumber *ngIf="field.fieldType==fieldType.Number" inputId="integeronly" class="input"
                id="field.fieldName" name="field.fieldName" autocomplete="new-sett"
                (onBlur)="onChangeField($event)" [formControlName]="field.fieldId" #numberInput>
            </p-inputNumber>
            <p-calendar *ngIf="field.fieldType==fieldType.Date" #dateField appendTo="body" inputId="calendarToDate"
                dateFormat="dd/mm/yy" [formControlName]="field.fieldId" (onSelect)="onChangeField($event)"
                (onBlur)="onChangeField($event)" (onClear)="onClearField()" dateMask
                [(ngModel)]="dateValue"></p-calendar>

            <p-dropdown *ngIf="field.fieldType==fieldType.Dropdown" [options]="field.defaultValue" 
                [(ngModel)]="field.value" (onChange)="onChangeField($event)" [autoDisplayFirst]="false"
                [formControlName]="field.fieldId">
            </p-dropdown>
            <label for="control{{field.fieldId}}" [ngClass]="flexStyle==true? 'col-3':''">
                <span>{{field.fieldName}}</span>
            </label>
        </span>
    </div>
</div>