<div class="layout-topbar">

    <div class="layout-topbar-left ">
        <div class="fast-payment-button justify-content-center align-items-center"
            style=" background: #cae8fc 0% 0% no-repeat padding-box;width: 6rem;
        box-shadow:  2px 0 4px -1px rgba(0, 0, 0, 0.2), 4px 0 5px 0  1px 0 10px 0 rgba(0, 0, 0, 0.12);height: inherit;">
            <a class="layout-menu-button  menuButton" (click)="openCloseMenu($event)" pRipple style="    right: 0;
             position: inherit;
             top: 0;
             margin-top: 1.5rem;">
                <i> <img style="height:2.5rem;" src="assets/images/icons/menu.png"></i>
            </a>
        </div>
        <div class="mobile-fast-payment-button">
            <a class="layout-menu-button  menuButton" (click)="openCloseMenu($event)" pRipple>
                <i> <img style="height: 1.5rem;" src="assets/images/icons/menu.png"></i>
            </a>
        </div>
        <a class="layout-topbar-logo " [routerLink]="logoRouterLink">
            <img id="app-logo " class="p-3 md:p-1"  src="/assets/images/kesher_logo.png" alt="ultima-layout" style="height: 3.5rem;">
        </a>
        <a class="layout-topbar-mobile-button" (click)="defaultLayout.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>
        <div class="flex gap-1" *ngIf="(company$| async)">
            <div class="fast-payment-button">
                <button pButton pRipple (click)="openFastPayment()" label="{{'TOP_MENU.FAST_PAYMENT'|translate}}"
                    class="p-button-outlined roundButton" icon="pi pi-credit-card"></button>
            </div>
            <div class="mobile-fast-payment-button">
                <button pButton pRipple type="button" (click)="openFastPayment()" class="text-white"
                    icon="pi pi-credit-card"></button>
            </div>
            <div class="ml-2 fast-payment-button">
                <button pButton type="button" (click)="openKesherProducts()"
                    label="{{'TOP_MENU.KESHER_PRODUCTS'|translate}}" icon="pi pi-cog"
                    class="p-button-outlined roundButton"></button>
            </div>
            <div class="ml-2 mobile-fast-payment-button">
                <button pButton type="button" (click)="openKesherProducts()" icon="pi pi-cog"
                    class="text-white"></button>
            </div>
        </div>
    </div>
    <div class="layout-topbar-right flex-grow-0 mr-auto company"
        [ngClass]="{'layout-topbar-mobile-active': defaultLayout.mobileTopbarActive}">
        <div class="layout-topbar-actions-left grid mb-auto mt-auto gap-1">


            <div [pTooltip]="'TOP_MENU.CHANGE_COMPANY' | translate" tooltipPosition="top">
                <img *ngIf="(company$| async)?.imgPath " (click)="displayChangeCompany = true"
                    src="{{(company$| async)?.imgPath}}" class="rounded-circle h-3rem w-3rem cursor-pointer"
                    alt="avatar" />

                <div *ngIf="!(company$| async)?.imgPath" (click)="displayChangeCompany = true"
                    class="rounded-circle h-3rem w-3rem bg-primary text-center flex justify-content-center align-items-center cursor-pointer">
                    {{logoFirstLetterCompanyName((company$| async)?.companyName)}}
                </div>
            </div>
            <div [pTooltip]="'TOP_MENU.DEFINITIONS_AND_PAYMENTS' | translate" tooltipPosition="top"
                class="display-block fw-900 hidden md:inline font-bold pl-3 pointer"
                [routerLink]="companyNameRouterLink">
                {{(company$| async)?.companyCode}}<b> | </b>{{(company$|async)?.companyName}}
            </div>



            <div >
                <div class="userNameWarpper   flex flex-column justify-content-center gap-1/2  xl:p-3 lg:p-3 md:p-2 sm:p-2 p-2 lg:h-5rem md:h-4rem  sm:h-4rem">

                    <span  [pTooltip]="'TOP_MENU.PERSONAL_DETAILS' | translate" class="display-block floatingMenu userName  pointer" (click)="op.toggle($event)"> שלום
                        {{(userInfo$| async)?.firstName}}
                        {{(userInfo$| async)?.lastName}}</span>

                    <p-overlayPanel #op>
                        <ng-template pTemplate>
                            <option class="pointer" (click)="openUserDetails()">עריכת פרטי משתמש</option>
                            <option class="pointer pt-1" (click)="authService.logout()">התנתק</option>
                        </ng-template>
                    </p-overlayPanel>


                    <span class="fs-small pl-3 userName" *ngIf="lastEntranceDate"> כניסה אחרונה: {{lastEntranceDate|
                        date:'HH:mm
                        dd/MM/yy'}}
                    </span>

                </div>
            </div>
        </div>
    </div>
</div>

<company [displayCompaines]="displayChangeCompany" (onClose)="onCloseCompanies()"></company>
<!------USER EDIT MODAL------>
<dynamic-popup [header]="'TOP_MENU.USER_DETAILS.TITLE' | translate" [mode]="popupModes.EditUser" [modal]="true"
    [visible]="displayUserEditModal" [style]="{width: '25vw'}" acceptLabel="GENERAL.OK" cancelLabel="GENERAL.CANCEL"
    (cancel)="onCloseUserEditModal($event)" (accept)="aaa()">
    <user-details #content [userInfo]="userInfo" *ngIf="displayUserEditModal"></user-details>
</dynamic-popup>