import { Component, HostListener, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'lib-swipe-card',
  templateUrl: './swipe-card.component.html',
  styleUrls: ['./swipe-card.component.scss']
})
export class SwipeCardComponent implements OnInit {
  swipe_buffer: string = "";
  swipe_timeout = null;
  SWIPE_TIMEOUT_MS = 100;
  creditNum: string = "";
  expiry: string = "";
  expYear = "";
  expMonth = "";


  constructor(public ref: DynamicDialogRef) {

  }

  ngOnInit(): void {
    window.addEventListener('keypress', this.onkeyPressed.bind(this));
    var self: any = this;
    try {
      window.addEventListener("message", function (event) {
        //Security: Validate message origin
        // if (event.origin !== 'https://kesherhk.info') {
        //   return;
        // }

        var track = event.data;
        var card = track.split('D')[0];
        var exp = track.split('D')[1].substring(0, 4);
        self.expMonth = exp.substring(2);
        self.expYear = '20' + exp.substring(0, 2);
        self.creditNum = card.toString();
        self.expiry = self.expMonth + "/" + self.expYear.substring(2);
        (window as any).removeAllListeners("keypress");
        self.closeDialog();
      });
    } catch (error) {

    }
    if (typeof (window?.Kesher) != "undefined") {
      try {
        window?.Kesher?.getCard("1");
      }
      catch (error) {
      }

    }
  }
  onkeyPressed(event) {

    this.addKeyToSwipeBuffer(event.keyCode, String.fromCharCode(event.keyCode));
    if (this.swipe_timeout == null) {
      this.swipe_timeout = setTimeout(() => this.parseAndDistributeSwipeBuffer(), this.SWIPE_TIMEOUT_MS);
    } else {
      clearTimeout(this.swipe_timeout);
      this.swipe_timeout = setTimeout(() => this.parseAndDistributeSwipeBuffer(), this.SWIPE_TIMEOUT_MS);
    }
  };

  addKeyToSwipeBuffer(keyCode, keyChar) {
    if (this.swipe_buffer == null) {
      this.swipe_buffer = "";
    }
    this.swipe_buffer += keyChar;
  };


  parseAndDistributeSwipeBuffer() {

    var cc_number;

    // parse new value
    var full_cc_regex = /(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})[=D][0-9]{4}/;
    var cc_regex = /(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})/;
    var exp_regex = /=(\d{4})/;

    if (this.swipe_buffer.match(full_cc_regex)) {
      var exp = exp_regex.exec(this.swipe_buffer);
      this.expMonth = exp[1].substring(2);
      this.expYear = '20' + exp[1].substring(0, 2);
      cc_number = cc_regex.exec(this.swipe_buffer);
      this.creditNum = cc_number.toString();
      this.expiry = this.expMonth + "/" + this.expYear.substring(2);
      (window as any).removeAllListeners("keypress");
      this.closeDialog();
      this.clearSwipeBuffer();
    }
    else {
    }

  };
  clearSwipeBuffer() {
    this.swipe_buffer = null;
  }
  closeDialog() {
    if (typeof (window?.Kesher) != "undefined") {
      try {
        window?.Kesher?.getCard("0");
      }
      catch (error) {
      }

    }
    this.ref.close({ creditNum: this.creditNum, expYear: this.expYear, expMonth: this.expMonth });
  }




}
declare global {
  interface Window {
    Kesher: {
      onCard: (err: string | null, card?: string, exp?: string) => void,
      getCard: (status: string) => void,
    }
  }
}

