import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppAuthService } from '../../../kesher-shared/services/app-auth.service';
import { AdvMessageService, DynamicPopupMode, FormValidationsService, LocalizationService, LoginStatus, OwnValidators, Role, VerificationType, verificationCodeModel } from 'projects/shared/src/public-api';
import { UsersService } from '../../../management/services/users.service';
@Component({
  selector: 'user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  UserDetailsForm: FormGroup = new FormGroup({});

  constructor(private userService: UsersService,
    private messageService: AdvMessageService,
    private localizationService: LocalizationService,
    private cdr: ChangeDetectorRef,
    public authService: AppAuthService,
    private formValidationService: FormValidationsService
  ) { }

  @Input() userInfo: any;

  showVerifyPopup: boolean = false;
  popupModes = DynamicPopupMode;
  verificationCodeDetails: verificationCodeModel;
  verificationCode = "";
  enableSubmit: boolean;
  phoneNumber: string;
  type: number;
  header: string;
  mailVerificationSuccess: boolean = false;
  phoneVerificationSuccess: boolean = false;
  originalUserInfo: any;
  
  ngOnInit(): void {
    this.originalUserInfo = this.userInfo
    this.loadForm();
  }

  private getTranslation(translationWord, parameter?) {
    return this.localizationService.Translate(translationWord, parameter)
  }

  afterVerification(isVerify) {
    if (isVerify == true) {
      this.enableSubmit = true;
      if (this.type == VerificationType.Mail)
        this.mailVerificationSuccess = true;
      else this.phoneVerificationSuccess = true;
    }

    else
      this.enableSubmit = false;
    this.showVerifyPopup = false;

  }

  onChangePhone() {
    this.phoneVerificationSuccess = false
    this.cdr.markForCheck();
  }

  onChangeMail() {
    this.mailVerificationSuccess = false
    this.cdr.markForCheck();
  }

  acceptMethod() : Promise<any>{
    return new Promise((resolve, reject) => {
    if (!this.UserDetailsForm.valid) {
      this.formValidationService.markFormGroupTouched(this.UserDetailsForm);
      resolve(false);
    }

    if (this.originalUserInfo.phone != this.UserDetailsForm.controls['phone'].value && !this.phoneVerificationSuccess ||
      this.originalUserInfo.userNameMail != this.UserDetailsForm.controls['mail'].value && !this.mailVerificationSuccess) {
      this.messageService.errorMessage("TOP_MENU.USER_DETAILS.ERROR_MESSAGE", 3000, true);
      resolve(false);
    }
    else {
      var user = {
        id: this.userInfo.identity,
        firstName: this.UserDetailsForm.controls['firstName'].value,
        lastName: this.UserDetailsForm.controls['lastName'].value,
        phone: this.UserDetailsForm.controls['phone'].value,
        mail: this.UserDetailsForm.controls['mail'].value
      }
      this.authService.updateLoginUser(user).subscribe((res: any) => {
        if (!res.succeeded) {
          if (res.status == LoginStatus.UserMailExists)
            this.messageService.errorMessage("כתובת מייל כבר קיימת במערכת")
          else if (res.status == LoginStatus.UserPhoneExists)
            this.messageService.errorMessage("מספר הטלפון כבר קיים במערכת")
          else
            this.messageService.errorMessage("GENERAL.MESSAGES.FAILED_SAVE_DATA", 3000, true);
            resolve(false);
        }
        else if (res.succeeded) {
          this.userInfo = this.authService.getUserInfo().subscribe(res => {
            this.messageService.successMessage("GENERAL.MESSAGES.DATA_SAVED_SUCCESSFULLY");
          });
          resolve(true);
        }

      })
    }})
  }

  closeModal() {
    this.showVerifyPopup = false;
  }

  loadForm() {
    this.UserDetailsForm = new FormGroup({
      firstName: new FormControl(this.userInfo.firstName),
      lastName: new FormControl(this.userInfo.lastName),
      phone: new FormControl(this.userInfo.phone, [Validators.required, OwnValidators.validPhone]),
      mail: new FormControl(this.userInfo.userNameMail, [Validators.required, Validators.email])
    });
  }

  openVerifyPopup(type) {
    this.header = type == VerificationType.Phone ? this.getTranslation('TOP_MENU.USER_DETAILS.PHONE_VERIFY') : this.getTranslation('TOP_MENU.USER_DETAILS.MAIL_VERIFY');
    this.phoneNumber = type == VerificationType.Phone ? this.UserDetailsForm.controls['phone'].value
      : this.UserDetailsForm.controls['mail'].value;
    this.type = type;
    this.showVerifyPopup = true;
  }
}
