<!-- <p-tag #rangeBtn class="rangeDatesTag" pRipple styleClass="shadow-5" icon="pi pi-calendar" (click)="op.toggle($event)"
        [rounded]="true">
        {{'ENUMS.RangeDates.'+label| translate}} {{ fromDate | date: 'dd/MM/yyyy'}} - {{toDate | date: 'dd/MM/yyyy'}}
    </p-tag> -->



<!-- class="p-button-info p-button-rounded rangesButton" -->
<!-- {{'ENUMS.RangeDates.'+label| translate}} -->



<button class="w-full md:w-auto font-bold p-button-icon-only px-0 p-button-outlined" type="button" pButton pRipple
    [ngClass]="differentDesign ? 'whiteDesign' : 'defaultDesign'">
    <div class="flex align-items-center gap-1-4">
        <div class="flex px-1" (click)="setRanges(rangeDates.PrevMonth,null,null)"
            pTooltip="{{'ENUMS.RangeDates.PrevMonth'|translate}}" tooltipPosition="right">
            <!-- <img class="search-button" [ngClass]="{'ChangeIconColor': differentDesign == true }"
                src="/assets/images/customerCard/sm-arrow-right.png"> -->
                <i class="pi pi-chevron-right"></i>
        </div>
        <div class="flex align-items-center gap-1-4" (click)="op.toggle($event)">
            <!-- <img src="assets/images/icons/calender.png" class="h-1rem mb-1" [hidden]="differentDesign == true"> -->
             <i class="pi pi-calendar"></i>
            <span> {{ fromDate | date: 'dd/MM/yyyy'}} - {{toDate | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="flex px-1" (click)="setRanges(rangeDates.NextMonth,null,null)"
            pTooltip="{{'ENUMS.RangeDates.NextMonth'|translate}}" tooltipPosition="left">
            <!-- <img class="search-button" [ngClass]="{'ChangeIconColor': differentDesign == true }"
                src="/assets/images/customerCard/sm-arrow-left.png"> -->
                <i class="pi pi-chevron-left"></i>
        </div>
    </div>
</button>


<p-overlayPanel #op [showCloseIcon]="true" >
    <ng-template pTemplate>
        <div class="header">
            <div style="width:9rem">
                <label>{{'DATES.FROM_DATE' |translate}}</label>
                <p-calendar [readonlyInput]="isMobile==true?true:false" (onInput)="deSelectSummeryRow()"
                    (onSelect)="deSelectSummeryRow()" dateMask inputId="calendarFromDate" dateFormat="dd/mm/yy"
                    [maxDate]="toDate" [(ngModel)]="fromDate">
                </p-calendar>
            </div>
            <div class="flex flex-column align-items-center justify-content-between">
                <i class="pi pi-arrow-left"></i>
                <button style="background: #ffffff;text-align: center;justify-content: center;align-items: center;
                margin-bottom: 0.3;
                margin: 0.1rem;
                color: #0f74b5;" pButton (click)="setRanges(rangeDates.CurrentDay,op,$event)"
                    label="{{'ENUMS.RangeDates.CurrentDay'|translate}}"></button>
            </div>
            <div style="width:9rem">
                <label>{{'DATES.TO_DATE' |translate}}</label>
                <p-calendar [readonlyInput]="isMobile?true:false" (onInput)="deSelectSummeryRow()"
                    (onSelect)="deSelectSummeryRow()" dateMask inputId="calendarToDate" dateFormat="dd/mm/yy"
                    [minDate]="fromDate" [maxDate]="toDateMaxDate" [(ngModel)]="toDate">
                </p-calendar>
            </div>
        </div>
        <div>
            <button pButton type="button" icon="pi pi-arrow-right" (click)="setRanges(rangeDates.PrevMonth,null,null)"
                class="dateButton p-button-rounded p-button-outlined"></button>
            <button class="dateButton monthButton"
                (click)="setRanges(rangeDates.CurrentMonth,op,$event)">{{'ENUMS.RangeDates.CurrentMonth'
                |translate}}</button>
            <button pButton type="button" icon="pi pi-arrow-left" (click)="setRanges(rangeDates.NextMonth,null,null)"
                class="dateButton p-button-rounded p-button-outlined"></button>
        </div>
        <div> <button class="dateButton range"
                (click)="setRanges(rangeDates.LastThirtyDays,op,$event)">{{'ENUMS.RangeDates.LastThirtyDays'
                |translate}}</button>
        </div>
        <div> <button class="dateButton range" (click)="setRanges(rangeDates.Quarterly,op,$event)">
                {{'ENUMS.RangeDates.Quarterly'
                |translate}}</button></div>
        <div> <button class="dateButton range" (click)="setRanges(rangeDates.YearAgo,op,$event)">
                {{'ENUMS.RangeDates.YearAgo'
                |translate}}</button>
        </div>
    </ng-template>
</p-overlayPanel>
