import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, TemplateRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState, selecCompanyInCommonState, selectUserInCommonState } from '../../../app-state.index';
import { PermissionMenuItem } from '../menu/permission-menu-item';
import { SideBarService } from 'projects/shared/src/public-api';

@Component({
    selector: 'app-default-layout',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DefaultLayoutComponent implements OnInit {

    topbarMenuActive: boolean;
    menuActive: boolean;
    staticMenuDesktopInactive: boolean;
    mobileMenuActive: boolean;
    menuClick: boolean;
    mobileTopbarActive: boolean;
    topbarRightClick: boolean;
    topbarItemClick: boolean;
    activeTopbarItem: string;
    documentClickListener: () => void;
    rightMenuActive: boolean;
    menuHoverActive = false;
    searchClick = false;
    search = false;
    subscriptions: Subscription = new Subscription();
    userInfo$: Observable<any>;
    company$: Observable<any>;
    visibleSidebar = false;
    contentSideBar: TemplateRef<any>;
    contentCanDeactive: () => Promise<any>;
    isLoading$: any;
    sidebarStyleClass: string;
    activeMenuItem: PermissionMenuItem;


    constructor(
        private sideBarServise: SideBarService,
        private store: Store<AppState>,
        public renderer: Renderer2,
        private cdr: ChangeDetectorRef
    ) {
        //  private menuService: MenuService
    }

    ngOnInit() {
        this.menuActive = this.isMobile();
        this.subscriptions.add(this.sideBarServise.contentSideBar.subscribe((content: TemplateRef<any>) => {
            if (this.sideBarServise.styleClass) {
                this.sidebarStyleClass = this.sideBarServise.styleClass;
                this.sideBarServise.styleClass = null;
            }
            else
                this.sidebarStyleClass = "p-sidebar-lg";

            this.contentSideBar = content;
            this.visibleSidebar = (content) ? true : false;

        }));
        this.subscriptions.add(this.sideBarServise.contentDeActivate.subscribe((contentDeActivate: () => Promise<any>) => {
            this.contentCanDeactive = contentDeActivate;
        }));
        this.userInfo$ = this.store.select(selectUserInCommonState);
        this.company$ = this.store.select(selecCompanyInCommonState);
    }

    activeItem(event) {
        this.activeMenuItem = event;
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        // hides the horizontal submenus or top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', () => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            // if (!this.menuClick) {
            //     this.menuService.reset();
            // }

            if (!this.menuClick) {
                if (this.mobileMenuActive) {
                    this.mobileMenuActive = false;
                }
                this.menuHoverActive = false;
                this.unblockBodyScroll();
            }

            if (!this.searchClick) {
                this.search = false;
            }
            this.searchClick = false;
            this.topbarItemClick = false;
            this.topbarRightClick = false;
            this.menuClick = false;
        });
    }

    onMenuButtonClick(event) {
        this.menuActive = !this.menuActive;
        this.onButtonClick(event);
    }

    onSideMenuClick(event) {
        this.menuActive = true;
        this.onButtonClick(event);
    }

    onButtonClick(event) {
        this.topbarMenuActive = false;
        this.topbarRightClick = true;
        this.menuClick = true;

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.mobileMenuActive = !this.mobileMenuActive;
            if (this.mobileMenuActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onTopbarMobileButtonClick(event) {
        this.mobileTopbarActive = !this.mobileTopbarActive;
        event.preventDefault();
    }

    onRightMenuButtonClick(event) {
        this.rightMenuActive = !this.rightMenuActive;
        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
    }

    onSearchKeydown(event) {
        if (event.keyCode === 27) {
            this.search = false;
        }
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        }
        else {
            this.activeTopbarItem = item;
        }

        if (item === 'search') {
            this.search = !this.search;
            this.searchClick = !this.searchClick;
        }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    removeSideBar() {
        if (!this.contentCanDeactive) {
            return;
        }
        this.contentCanDeactive().then((res: boolean) => {
            if (!res) {
                this.visibleSidebar = true;
                return;
            }
            this.sideBarServise.setContentSideBar(null);
            this.sideBarServise.setContentDeActivate(null);
        });
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

}
