import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BankAccountNumberService } from 'projects/shared/src/public-api';
import { Bank } from '../../external-models/bank';
import { Branch } from '../../external-models/branch';
import { BankService } from '../../services/bank.service';

@Component({
  selector: 'account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  private _accountDetails: FormGroup = new FormGroup({
    bank: new FormControl(null, [Validators.required]),
    branch: new FormControl(null, [Validators.required]),
    account: new FormControl("",
      {
        validators: [Validators.required, Validators.minLength(4), Validators.maxLength(9), Validators.pattern(/[0-9]+/)],
        asyncValidators: [this.bankAccountNumberService.asyncAccountNumberValidator],
        updateOn: 'blur'
      })
  });
  @Input() public get accountDetails(): FormGroup {
    return this._accountDetails;
  }
  public set accountDetails(form: FormGroup) {
    this._accountDetails = form;
    this.addRequierdIfOneFilledValidation();
     
  }

  @Input() selectedBankId: number = null;
  @Input() selectedBranchId: number = null;
  @Input() requiredIfOneFilled:boolean=false;

  private _isRequired: boolean = true;
  @Input() public get isRequired(): boolean {
    return this._isRequired;
  }
  public set isRequired(v: boolean) {
    this._isRequired = v;
    this.setValidatorsAndValue();
  }


  banks: Bank[];
  selectedBank: Bank;
  branches: Branch[];
  selectedBranch: Branch;
  bankBranches: Branch[];
  isUpdateValidations = 0;

  @ViewChild("bank") bank: any;
  constructor(private bankService: BankService,
    private bankAccountNumberService: BankAccountNumberService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (!this.accountDetails.controls.hasOwnProperty("bank"))
      this.initFormGroup();
    this.getBanks();
    this.getBranches();
  }
  ngAfterViewInit() {
    this.removeClass();
    setTimeout(() => {
      this.removeClass();
    }, 200);
  }
  removeClass() {
    this.bank?.el?.nativeElement.classList.remove('ng-dirty');
  }

  initFormGroup() {
    if (!this.accountDetails)
      this.accountDetails = new FormGroup({});

    this.accountDetails.addControl("bank", new FormControl(null, this._isRequired ? [Validators.required] : []));
    this.accountDetails.addControl("branch", new FormControl(null, this._isRequired ? [Validators.required] : []));
    this.accountDetails.addControl("account", new FormControl("",
      this._isRequired ? {
        validators: [Validators.required, Validators.minLength(4), Validators.maxLength(9), Validators.pattern(/[0-9]+/)],
        asyncValidators: [this.bankAccountNumberService.asyncAccountNumberValidator],
        updateOn: 'blur'
      } : {}));
      this.updateValidation();
  }

  setValidatorsAndValue() {

    this.accountDetails.setControl("bank", new FormControl(this.accountDetails.controls.bank?.value, this._isRequired ? [Validators.required] : []));
    this.accountDetails.controls['bank']?.updateValueAndValidity();

    this.accountDetails.setControl("branch", new FormControl(this.accountDetails.controls.branch?.value, this._isRequired ? [Validators.required] : []));
    this.accountDetails.controls['branch']?.updateValueAndValidity();

    this.accountDetails.setControl("account", new FormControl(this.accountDetails.controls['account']?.value,
      this._isRequired ? {
        validators: [Validators.required, Validators.minLength(4), Validators.maxLength(9), Validators.pattern(/[0-9]+/)],
        asyncValidators: [this.bankAccountNumberService.asyncAccountNumberValidator],
        updateOn: 'blur'
      } : {}));

    // if (this._isRequired)
    //   this.accountDetails.controls['account'].enable();
    // else
    //   this.accountDetails.controls['account'].disable();
    this.accountDetails.controls['account']?.updateValueAndValidity();



    this.accountDetails.updateValueAndValidity();


  }


  getBanks() {
    this.bankService.getBanksList().subscribe(res => {
      this.banks = res as Bank[];
      this.selectedBank = this.banks.find(x => x.id == this.selectedBankId);
      this.updateValidation();
      this.cdr.detectChanges();
      this.filterBranches();
    });


  }

  getBranches() {
    this.bankService.getBranchesList().subscribe(res => {
      this.branches = res as Branch[];
      this.cdr.detectChanges();
      this.filterBranches();
    });
  }

  filterBranches() {
    if (this.branches && this.selectedBank) {
      this.bankBranches = this.branches.filter(x => x.bankId == this.selectedBank.id);
      this.selectedBranch = this.bankBranches.find(x => x.id == this.selectedBranchId);
      this.updateValidation();
      this.cdr.detectChanges();
    }
  }

  addRequierdIfOneFilledValidation(){
    this.accountDetails.get('bank').valueChanges.subscribe(value => {
      this.updateValidation();
    });

    this.accountDetails.get('branch').valueChanges.subscribe(value => {
     this.updateValidation();
    });

    this.accountDetails.get('account').valueChanges.subscribe(value => {
      this.updateValidation();
    });
    this.updateValidation();
  }

  updateValidation(){
    if(this.requiredIfOneFilled){
      const bank = this.accountDetails.get('bank');
      const branch = this.accountDetails.get('branch');
      const account = this.accountDetails.get('account');
      if (bank.value || bank.value || account.value) {
        bank.setValidators([Validators.required]);
        branch.setValidators([Validators.required]);
        account.setValidators([Validators.required]);
      } else {
        bank.setValidators([Validators.nullValidator]);
        branch.setValidators([Validators.nullValidator]);
        account.setValidators([Validators.nullValidator]);
      }
  
      bank.updateValueAndValidity();
      branch.updateValueAndValidity();
      account.updateValueAndValidity();
      this.isUpdateValidations++;
    }
  }
}
