import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { EnumService } from 'shared/src/enum';
import { BlockableDivComponent } from './components/blockable-div/blockable-div.component';
import { CustomMessageComponent } from './components/customMessage/customMessage.component';
import { DelayMessageComponent } from './components/delay-message/delay-message.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EnumModule } from 'shared/src/enum';
import { LocalizationModule } from 'shared/src/localization';
import { CodeVerificationComponent } from './components/code-verification/code-verification.component';
import { DelayMessageService } from './components/delay-message/delay-message.service';
import { SignatureDirective } from './directives/signature.directive';
import { CommonService } from './services/common.service';
import { AdvMessageService } from './services/message.service';
import { MetaService } from './services/meta.service';
import { commonReducer } from './state/state.index';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';


@NgModule({
  declarations: [CustomMessageComponent,
    DelayMessageComponent,
    BlockableDivComponent,
    CodeVerificationComponent,
    SignatureDirective,
  ],
  imports: [
    InputTextModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ConfirmDialogModule,
    ToastModule,
    DialogModule,
    ProgressBarModule,
    StoreModule.forFeature('shared-common', commonReducer),
    LocalizationModule.forChild(),
    EnumModule.forChild()

  ],
  exports: [
    StoreModule,
    SignatureDirective,
    CodeVerificationComponent,
    CustomMessageComponent,
    DelayMessageComponent,
    BlockableDivComponent,

    LocalizationModule,
    EnumModule,

    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    ToastModule,
    DialogModule,

  ],
  providers: [
    DelayMessageService,
    MetaService,
    EnumService,
    CommonService,
    AdvMessageService]
})
export class SharedCommonModule {
  static forChild(): ModuleWithProviders<SharedCommonModule> {
    return {
      ngModule: SharedCommonModule,
      providers: [
        
      ]
    }
  }
}
