<div>
    <h6>
        {{ (editAble ? 'TERMINALS.DEFAULT_TERMINAL.EDITABLE_TITLE' : 'TERMINALS.DEFAULT_TERMINAL.UN_EDITABLE_TITLE' )|
        translate }}
    </h6>
    <form [formGroup]="defaultTerminalsForm" class="flex flex-column  gap-1">
        <div class="flex justify-content-between flex-column">
            <p-checkbox *ngIf="editAble" name="defaultTerminal" formControlName="defaultTerminal" [binary]="true"
                label="כרטיסים רגילים"></p-checkbox>
            <label [ngClass]="!editAble?'flex gap-1':''">
                <span [hidden]="editAble"> כרטיסים רגילים</span>
                <div *ngIf="defaultTerminals?.regullar?.terminalNumber&&defaultTerminal!=true">
                    <span *ngIf="!editAble">[</span>
                    <span *ngIf="editAble" class="fs-xsmall" style="color: gray;">
                        בבחירת מסוף זה כסולק ברירת מחדל למותג הינך מבטל את מסוף
                    </span>
                    <span class="fs-small">
                        {{defaultTerminals.regullar.terminalNumber.substring(0,defaultTerminals.regullar.terminalNumber.length-3)}}
                        -
                        {{defaultTerminals.regullar.name}}</span>
                    <span *ngIf="!editAble">]</span>
                </div>
            </label>
        </div>
        <div class="flex justify-content-between  flex-column">
            <p-checkbox *ngIf="editAble" name="defaultAmexTerminal" formControlName="defaultAmexTerminal"
                [binary]="true" label="כרטיסי אמקס"></p-checkbox>
            <label [ngClass]="!editAble?'flex gap-1':''">
                <span [hidden]="editAble"> כרטיסי אמקס</span>
                <div *ngIf="defaultTerminals?.amex?.terminalNumber&&defaultAmexTerminal!=true">
                    <span *ngIf="!editAble">[</span>
                    <span *ngIf="editAble" class="fs-xsmall" style="color: gray;">
                        בבחירת מסוף זה כסולק ברירת מחדל למותג הינך מבטל את מסוף
                    </span>
                    <span class="fs-small">
                        {{defaultTerminals.amex.terminalNumber.substring(0,
                        defaultTerminals.amex.terminalNumber.length-3)}}
                        -
                        {{defaultTerminals.amex.name}}
                    </span>
                    <span *ngIf="!editAble">]</span>
                </div>
            </label>
        </div>
        <div class="flex justify-content-between flex-column">
            <p-checkbox *ngIf="editAble" name="defaultDinersTerminal" formControlName="defaultDinersTerminal"
                [binary]="true" label="כרטיסי דיינרס"></p-checkbox>
            <label [ngClass]="!editAble?'flex gap-1':''">
                <span [hidden]="editAble"> כרטיסי דיינרס</span>
                <div *ngIf="defaultTerminals?.diners?.terminalNumber&&defaultDinersTerminal!=true">
                    <span *ngIf="!editAble">[</span>
                    <span *ngIf="editAble" class="fs-xsmall" style="color: gray;">
                        בבחירת מסוף זה כסולק ברירת מחדל למותג הינך מבטל את מסוף
                    </span>
                    <span class="fs-small">
                        {{defaultTerminals.diners.terminalNumber.substring(0,
                        defaultTerminals.diners.terminalNumber.length-3)}}
                        -
                        {{defaultTerminals.diners.name}}</span>
                    <span *ngIf="!editAble">]</span>

                </div>
            </label>
        </div>
    </form>
</div>